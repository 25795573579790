import React from "react";
import { GoArrowUpRight } from 'react-icons/go';
import logo from '../../assets/images/logo.png';
import { ButtonGroup, HeaderButton } from "../../section/hero/style";
import { SectionWrapper } from "../../styles";
import { NavbarContainer, NavbarMenu, NavbarWrapper } from "./style";

export const Navbar = () => {
    return (
        <NavbarWrapper>
            <SectionWrapper style={{ padding: "0" }}>
                <NavbarContainer>
                    <a href="/">
                        <img src={logo} alt="logo" />
                    </a>
                    <NavbarMenu>
                        <a href="/">Home</a>
                        <a href="#services">Services</a>
                        <a href="#projects">Our Works</a>
                        <a href="#contacts">Contact</a>
                        <ButtonGroup>
                            <HeaderButton href="https://wa.me/6281905388170?text=Hi there! Can I setup a schedule for consultation?">
                                Contact Us <GoArrowUpRight size={20} />
                            </HeaderButton>
                        </ButtonGroup>
                    </NavbarMenu>
                </NavbarContainer>
            </SectionWrapper>
        </NavbarWrapper>
    )
}