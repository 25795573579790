import styled from "styled-components";

export const SectionWrapper = styled.section`
    margin: 0 auto;
    position: relative;
    padding: 1rem;

    @media screen and (min-width: 1024px) {
        max-width: 1280px;
        padding: 1rem 0;
    }
`;
