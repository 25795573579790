import React from "react";
import { Line, RainAnimate } from "./style";

export const Rain = () => {
    return (
        <RainAnimate>
            <Line />
            <Line />
            <Line />
            <Line />
            <Line />
        </RainAnimate>
    )
}