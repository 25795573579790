import styled from "styled-components";
import bg from '../../assets/images/footer.png';

export const FooterSection = styled.footer`
    background-color: #060a3d;
    position: relative;
    /* background-image: url(${bg}); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &::after {
        content: "";
        background: url(${bg});
        background-repeat: no-repeat;
        width: 906px;
        height: 1164px;
        background-size: contain;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
    }
`;

export const FooterWrapper = styled.div`
    position: relative;
    overflow: hidden;
    z-index: 1000;

    @media screen and (min-width: 1024px) {
        padding: 70px 0 70px 0;
    }
`;

export const FooterInner = styled.div`
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 1rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        padding: 0;
    }
`;

export const FooterInnerFlex = styled.div`
    & > h1 {
        color: #fff;
        font-size: 32px;
        line-height: 1.2;
    }

    & > h2 {
        color: #fff;
    }

    & > a {
        border: 1px solid #fff;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        padding: 5px;
        border-radius: 50px;
        text-decoration: none;
        margin-top: 2rem;

        & > div {
            background-color: #5d5ad6;
            width: 50px;
            height: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 0;
            outline: none;
        }

        & > span {
            padding: 0 20px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    @media screen and (min-width: 1024px) {
        flex: 0 0 33.333333%;
        max-width: 33%;
        flex-shrink: 1;
        flex-grow: 1;

         & > h1 {
            font-size: 42px;
         }
    }
`;

export const FooterMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;

    & > form {

        & > input {
            padding: 16px 15px;
            margin: 0;
            border-radius: 3px;
            color: #fff;
            border: 1px solid #1e2a37;
            background-color: #131c25;
            width: 100%;
            font-size: 16px;
        }

        & > button {
            background-color: #5d5ad6;
            color: #fff;
            margin: 0;
            padding: 12px 32px;
            font-size: 14px;
            border-radius: 3px;
            outline: 0;
            border: 0;
            margin-top: 12px;
            transition: .6s all;
            cursor: pointer;

            &:hover {
                background-color: #fff;
                color: #5d5ad6;
            }
        }
    }

    & > div {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #5d5ad6;

        & > span {
            color: #fff;
            font-size: 20px;
        }
    }
`;

export const BottomFooter = styled.div`
    background-color: #191d58;
    position: relative;
    z-index: 1001;
`;

export const BottomFooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 11px 1rem;
    gap: 8px;

    @media screen and (min-width: 1024px){
        flex-direction: row;
        align-items: center;
        padding: 11px 0;
        gap: 0;
    }

    & > span {
        color: #fff;
        font-size: 18px;
        font-weight: 500;

        & > a {
            &:visited{ 
                color: inherit;
            }
        }
    }
`;

export const SocialMediaFooterWrapper = styled.div`
    display: flex;
    gap: 8px;

    & > a {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        height: 38px;
        width: 38px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: .4s all;
        cursor: pointer;

        &:hover {
            background-color: #fff;
            color: #5d5ad6;
        }
    }
`;