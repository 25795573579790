import React from "react";
import { GoArrowUpRight } from 'react-icons/go';
import branding from '../../assets/images/services/branding.png';
import hover1 from '../../assets/images/services/hover_1.jpg';
import hover2 from '../../assets/images/services/hover_2.jpg';
import hover3 from '../../assets/images/services/hover_3.jpg';
import hover4 from '../../assets/images/services/hover_4.jpg';
import apps from '../../assets/images/services/photo.png';
import ui from '../../assets/images/services/ui.png';
import web from '../../assets/images/services/wd.png';
import shape from '../../assets/images/shape.png';
import { Rain } from "../../component/rain";
import { SectionWrapper } from "../../styles";
import { ButtonPart, CardContainer, ServiceCard, ServiceContainer, ServiceGrid, ServiceHeader, ServiceHeaderFlex, ShapeWrapper } from "./style";

export const Services = () => {
    return (
        <>
            <SectionWrapper id="services">
                <Rain />
                <ServiceContainer>
                    <span data-aos="fade-right">Our Services</span>
                    <ServiceHeaderFlex >
                        <ServiceHeader data-aos="fade-right">
                            <h1>Our Commitment to Excellence in Service</h1>
                            <p>Discover how our specialized services can transform your vision into reality.</p>
                        </ServiceHeader>
                        <ShapeWrapper data-aos="fade-left">
                            <img src={shape} alt="shape" />
                        </ShapeWrapper>
                    </ServiceHeaderFlex>
                    <ServiceGrid data-aos="fade-up">
                        <ServiceCard>
                            <CardContainer bgImage={hover1}>
                                <img src={web} alt="web" />
                                <h1>Web Design and Development</h1>
                                <p>Create stunning, responsive websites tailored to your brand for a seamless user experience.</p>
                                <ButtonPart>
                                    <a href="/">Read More <GoArrowUpRight size={20} /></a>
                                </ButtonPart>
                            </CardContainer>
                        </ServiceCard>
                        <ServiceCard>
                            <CardContainer bgImage={hover2}>
                                <img src={ui} alt="web" />
                                <h1>UI/UX Design and Graphic Design</h1>
                                <p>Design intuitive, visually appealing interfaces and graphics that elevate your brand.</p>
                                <ButtonPart>
                                    <a href="/">Read More <GoArrowUpRight size={20} /></a>
                                </ButtonPart>
                            </CardContainer>
                        </ServiceCard>
                        <ServiceCard>
                            <CardContainer bgImage={hover3}>
                                <img src={apps} alt="web" />
                                <h1>Mobile Apps Development</h1>
                                <p>Develop user-friendly mobile apps that meet your business goals and engage users.</p>
                                <ButtonPart>
                                    <a href="/">Read More <GoArrowUpRight size={20} /></a>
                                </ButtonPart>
                            </CardContainer>
                        </ServiceCard>
                        <ServiceCard>
                            <CardContainer bgImage={hover4}>
                                <img src={branding} alt="web" />
                                <h1>Branding & Social Media Specialist</h1>
                                <p>Enhance your brand's visibility with strong branding and effective social media strategies.</p>
                                <ButtonPart>
                                    <a href="/">Read More <GoArrowUpRight size={20} /></a>
                                </ButtonPart>
                            </CardContainer>
                        </ServiceCard>
                    </ServiceGrid>
                </ServiceContainer>
            </SectionWrapper>
        </>
    )
}