import styled from "styled-components";

export const RainAnimate = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    overflow: hidden;
    z-index: -1;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 1024px) {
        width: 1280px;
    }
`;

export const Line = styled.div`
    background-color: #f2f2f2;
    position: relative;
    width: 1px;
    height: 100%;
    top: 0;
    background: #e4e4e4;
    z-index: 0;
`;