import styled from "styled-components";

export const ProjectGrid = styled.div`
    display: grid;
    gap: 2rem;
    margin-top: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
`;

export const ProjectCard = styled.div`
    display: flex;
    flex-direction: column;
    transition: .6s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }

    & > img {
        width: 100%;
        border-radius: 30px;
        margin-bottom: 1rem;
    }

    & > h1 {
        font-size: 21px;
        font-weight: 700;
    }

    & > p {
        color: #666666;
        font-size: 18px;
        margin: 4px 0 0;
    }
`;

export const ProjectOthers = styled.div`
    margin-top: 2rem;

    & > span {
        color: #666666;
    }
`;

export const ProjectOtherFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    align-items: center;
    gap: 2px;

    & > span {
        font-size: 21px;
        transition: .5s ease;
        cursor: pointer;

        & > sup {
            font-size: 12px;
        }

        &:hover {
            color: #5D5AD6;
        }

        & > a {

            &:visited {
                color: inherit;
            }
        }
    }



    @media screen and (min-width: 1024px) {
        & > span {
            font-size: 30px;

            & > sup {
                font-size: 20px;
            }
        }
    }
`;