import { createGlobalStyle } from "styled-components";


import MetricBlackWoff from '../assets/fonts/Metric-Black.woff';
import MetricBlackWoff2 from '../assets/fonts/Metric-Black.woff2';
import MetricBoldWoff from '../assets/fonts/Metric-Bold.woff';
import MetricBoldWoff2 from '../assets/fonts/Metric-Bold.woff2';
import MetricLightWoff from '../assets/fonts/Metric-Light.woff';
import MetricLightWoff2 from '../assets/fonts/Metric-Light.woff2';
import MetricMediumWoff from '../assets/fonts/Metric-Medium.woff';
import MetricMediumWoff2 from '../assets/fonts/Metric-Medium.woff2';
import MetricRegularWoff from '../assets/fonts/Metric-Regular.woff';
import MetricRegularWoff2 from '../assets/fonts/Metric-Regular.woff2';
import MetricSemiBoldWoff from '../assets/fonts/Metric-SemiBold.woff';
import MetricSemiBoldWoff2 from '../assets/fonts/Metric-SemiBold.woff2';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Metric';
        src: url(${MetricSemiBoldWoff2}) format('woff2'),
            url(${MetricSemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Metric';
        src: url(${MetricRegularWoff2}) format('woff2'),
            url(${MetricRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Metric';
        src: url(${MetricMediumWoff2}) format('woff2'),
            url(${MetricMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Metric';
        src: url(${MetricLightWoff2}) format('woff2'),
            url(${MetricLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Metric';
        src: url(${MetricBoldWoff2}) format('woff2'),
            url(${MetricBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Metric';
        src: url(${MetricBlackWoff2}) format('woff2'),
            url(${MetricBlackWoff}) format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }


  * {
    font-family: 'Metric', sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: -.01em;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    font-family: 'Red Hat Text', sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth 
  }
`;