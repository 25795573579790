import styled from "styled-components";
import stats from '../../assets/images/stats.jpg';

export const StatsWrapper = styled.div`
    background-color: #10171E;
    background-image: url(${stats});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding-block-start: 60px;
    padding-block-end: 60px;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 30px;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 0;
    }
`;

export const StatsCard = styled.div`
    border-right: 1px solid #dddddd1f;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
        font-size: 70px;
        color: #5D5AD6;
    }

    & > span {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
    }
`;

export const StatsNumberWrapper = styled.div`
    display: flex;

    & > span {
        font-size: 81px;
        color: #5D5AD6;
    }

    & > h1 {
        font-size: 70px;
        color: #5D5AD6;
    }
`;