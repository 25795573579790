import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { HomePage } from './page/home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  }
])

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  })

  return (
    <RouterProvider router={router} />
  );
}

export default App;
