import React from "react";
import { Navbar } from "../../component/navbar";
import { Footer } from "../../section/footer";
import { Hero } from "../../section/hero";
import { Project } from "../../section/project";
import { Services } from "../../section/services";
import { Statistic } from "../../section/statistics";
import { Testimony } from "../../section/testimony";

export const HomePage = () => {
    return (
        <>
            <Navbar />
            <Hero />
            <Services />
            <Statistic />
            <Project />
            <Testimony />
            <Footer />
        </>
    )
}