import React from "react";
import { BiLogoWhatsapp, BiPhoneCall } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';
import { LuInstagram } from 'react-icons/lu';
import { MdOutlineMail } from 'react-icons/md';
import { RxTimer } from 'react-icons/rx';
import { SectionWrapper } from "../../styles";
import { BottomFooter, BottomFooterContainer, FooterInner, FooterInnerFlex, FooterMenu, FooterSection, FooterWrapper, SocialMediaFooterWrapper } from "./style";

export const Footer = () => {
    return (
        <>
            <FooterSection id="contacts">
                <SectionWrapper>
                    <FooterWrapper>
                        <FooterInner>
                            <FooterInnerFlex>
                                <h1>Let's start working together, get in touch!</h1>
                                <a href="https://wa.me/6281905388170?text=Hi there! Can I setup a schedule for consultation?"><div>
                                    <GoArrowUpRight size={25} color="#fff" /></div><span>Contact Us</span></a>
                            </FooterInnerFlex>
                            <FooterInnerFlex>
                                <h2>Contact Information</h2>
                                <FooterMenu>
                                    <div>
                                        <MdOutlineMail size={22} />
                                        <span>hello@arklify.com</span>
                                    </div>
                                    <div>
                                        <BiPhoneCall size={22} />
                                        <span>(+62)819-0538-8170</span>
                                    </div>
                                    <div>
                                        <RxTimer size={22} />
                                        <span>09.00 - 18.00</span>
                                    </div>
                                </FooterMenu>
                            </FooterInnerFlex>
                            <FooterInnerFlex>
                                <h2>Ask For Consultation</h2>
                                <FooterMenu>
                                    <form method="POST" action="https://formspree.io/f/xanwnwdr">
                                        <input name="email" type="email" placeholder="Email Address" />
                                        <button>Ask Now</button>
                                    </form>
                                </FooterMenu>
                            </FooterInnerFlex>
                        </FooterInner>
                    </FooterWrapper>
                </SectionWrapper>
            </FooterSection>
            <BottomFooter>
                <SectionWrapper>
                    <BottomFooterContainer>
                        <span>&#169; Copyrights Arklify 2024.</span>
                        <SocialMediaFooterWrapper>
                            <a href="https://instagram.com/arklify">
                                <LuInstagram />
                            </a>
                            <a href="https://wa.me/6281905388170?text=Hi there! Can I setup a schedule for consultation?">
                                <BiLogoWhatsapp size={20} />
                            </a>
                        </SocialMediaFooterWrapper>
                        <span>Design & Developed by <a href="https://bykace.com">ByKace</a></span>
                    </BottomFooterContainer>
                </SectionWrapper>
            </BottomFooter>
        </>

    )
}