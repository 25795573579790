import styled from "styled-components";

export const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: .4s all;
    background-color: rgba(6, 10, 61, 0.9);
    backdrop-filter: blur(5px);
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    & > a {
        & > img {
            width: 100px;
        }
    }



    @media screen and (min-width: 1024px) {
        padding: 0;

        & > a {
            & > img {
                width: 125px;
            }
        }
    }
`;

export const NavbarMenu = styled.div`
    display: none;
    align-items: center;
    gap: 1.5rem;

    & > a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        font-family: 'Red Hat Text', sans-serif;
        font-weight: 500;
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;