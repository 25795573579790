import React from "react";
import Marquee from "react-fast-marquee";
import { GoArrowUpRight } from 'react-icons/go';
import heroImg from '../../assets/images/hero_01.jpg';
import star from '../../assets/images/star.png';
import { SectionWrapper } from "../../styles";
import { ButtonGroup, HeaderButton, HeroHeader, HeroHeaderDetail, HeroWrapper, ImageWrapper, MarqueeContent, MarqueeWrapper } from "./style";

export const Hero = () => {
    return (
        <>
            <HeroWrapper>
                <SectionWrapper>
                    <HeroHeader>
                        <HeroHeaderDetail data-aos="fade-up">
                            <h1>Innovative. Reliable. Creative.</h1>
                            <h2>Crafting Digital Masterpieces: Your Vision, Our Code</h2>
                            <p>We craft stunning, user-friendly websites that elevate your brand.</p>
                        </HeroHeaderDetail>
                        <ButtonGroup data-aos="fade-up">
                            <HeaderButton href="https://wa.me/6281905388170?text=Hi there! Can I setup a schedule for consultation?">
                                Schedule a Consultation <GoArrowUpRight size={20} />
                            </HeaderButton>
                        </ButtonGroup>
                        <ImageWrapper data-aos="fade-up">
                            <img src={heroImg} alt="hero" />
                        </ImageWrapper>
                    </HeroHeader>
                </SectionWrapper>
            </HeroWrapper>
            <MarqueeWrapper>
                <Marquee speed={100} autoFill>
                    <MarqueeContent>
                        Software Development <img src={star} alt="star" />
                    </MarqueeContent>
                    <MarqueeContent>
                        UI Design <img src={star} alt="star" />
                    </MarqueeContent>
                    <MarqueeContent>
                        Social Media Specialist <img src={star} alt="star" />
                    </MarqueeContent>
                </Marquee>
            </MarqueeWrapper>
        </>
    )
}