import React from "react";
import styled from "styled-components";
import { SectionWrapper } from "../../styles";
import { ServiceContainer, ServiceHeader } from "../services/style";

const TestimonyGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

const TestimonyCard = styled.div`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
    /* background-color: #F2EEE4; */
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    transition: .6s ease;

    &:hover {
        transform: scale(1.05);
    }

    & > p {
        color: #757575;
        font-size: 20px;
    }

    @media screen and (min-width: 1024px) {
        & > p {
            height: 160px;
        }
    }
`;

const TestiFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    & > h1 {
        font-size: 21px;
        font-weight: 500;
    }

    & > h2 {
        font-family: 'Metric', sans-serif;
        font-weight: 300;
        font-size: 18px;
    }
`;

export const Testimony = () => {
    return (
        <SectionWrapper>
            <ServiceContainer>
                <span data-aos="fade-right">Client Testimonials</span>
                <ServiceHeader data-aos="fade-right">
                    <h1>Hear from Our Satisfied Customers</h1>
                    <p>The best way to showcase our commitment is through the experiences and stories of those who have partnered with us.</p>
                </ServiceHeader>
                <TestimonyGrid data-aos="fade-up">
                    <TestimonyCard>
                        <p>Working with Arklify has been an excellent experience. Their team is highly responsive and efficient, delivering a website that perfectly aligns with our vision. The communication throughout the project was clear and timely, ensuring a smooth and successful launch. Highly recommended!</p>
                        <TestiFooter>
                            <h1>Mr. Troy</h1>
                            <h2>Katalyst Capital</h2>
                        </TestiFooter>
                    </TestimonyCard>
                    <TestimonyCard>
                        <p>Arklify has truly transformed my interior design business. They built a beautiful, user-friendly website that perfectly captures my brand, and their team has been incredibly responsive and supportive throughout the process.</p>
                        <TestiFooter>
                            <h1>Mrs. Monica</h1>
                            <h2>The Local Studio</h2>
                        </TestiFooter>
                    </TestimonyCard>
                    <TestimonyCard>
                        <p>Arklify did an outstanding job revamping our corrugated boxes company website. They delivered a sleek, modern design that showcases our products perfectly, along with a fast and responsive user experience. The website has significantly boosted our online visibility and customer engagement.</p>
                        <TestiFooter>
                            <h1>Mr. Ivander</h1>
                            <h2>Perdana Megajaya Box</h2>
                        </TestiFooter>
                    </TestimonyCard>
                </TestimonyGrid>
            </ServiceContainer>
        </SectionWrapper>
    )
}