import styled from "styled-components";

export const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 999;
    padding: 4rem 1rem;

    & > span {
        color: #5D5AD6;
        font-weight: 700;
        font-family: 'Red Hat Text', sans-serif;
        font-size: 16px;
        text-transform: uppercase;
    }
`;

export const ServiceHeaderFlex = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ServiceHeader = styled.div`
    & > h1 {
        font-size: 36px;
        max-width: 600px;
        line-height: 1.1;
        margin: 12px 0 8px;
    }
    & > p {
        font-size: 20px;
        line-height: 1.5;
    }



    @media screen and (min-width: 1024px) {
        & > h1 {
            font-size: 52px;
        }


        & > p {
            max-width: 60%;
        }
    }

`;

export const ShapeWrapper = styled.div`
    display: none;
    padding: 40px 10px 0 0;

    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

export const ServiceGrid = styled.div`
    margin-top: 3rem;
    display: grid;
    gap: 1rem;

    @media screen and (min-width: 1080px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
`;

export const ServiceCard = styled.div`
    background-color: #fff;
    box-shadow: 0px 0px 40px 3px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

export const CardContainer = styled.div`
    background-color: #F5F5FE;
    padding: 40px 23px 0px 30px;
    margin: 10px 10px 0px 10px;
    flex: 1;
    transition: .6s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
        background-image: url(${({ bgImage }) => bgImage});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        & > p {
            color: #fff;
        }

        & > h1 {
            color: #fff;
        }
    }

    & > img {
        width: 60px;
        margin-bottom: 2rem;
    }

    & > p {
        margin-top: 8px;
        line-height: 1.5;
        color: #666;
        font-size: 18px;
        height: 110px;
    }
`;

export const ButtonPart = styled.div`
    margin: 0px -23px 0px -30px;

    & > a {
        text-decoration: none;
        background-color: #FFFFFF;
        padding: 17px 15px 15px;
        font-weight: 600;
        width: 100%;
        color: #10171E;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
    }
`;