import React from "react";
import CountUp from "react-countup";
import { SectionWrapper } from "../../styles";
import { StatsCard, StatsNumberWrapper, StatsWrapper } from "./style";

export const Statistic = () => {
    return (
        <SectionWrapper>
            <StatsWrapper data-aos="fade-up">
                <StatsCard>
                    <StatsNumberWrapper>
                        <CountUp
                            end={10}
                            duration={2}
                        />
                        <h1>+</h1>
                    </StatsNumberWrapper>
                    <span>Projects Complete</span>
                </StatsCard>
                <StatsCard>
                    <StatsNumberWrapper>
                        <CountUp
                            end={5}
                            duration={2}
                        />
                        <h1>+</h1>
                    </StatsNumberWrapper>
                    <span>Happy Clients</span>
                </StatsCard>
                <StatsCard>
                    <StatsNumberWrapper>
                        <CountUp
                            end={3}
                            duration={2}
                        />
                        <h1>+</h1>
                    </StatsNumberWrapper>
                    <span>Team Members</span>
                </StatsCard>
                <StatsCard>
                    <StatsNumberWrapper>
                        <CountUp
                            end={2}
                            duration={2}
                        />
                        <h1>+</h1>
                    </StatsNumberWrapper>
                    <span>Years of Experience</span>
                </StatsCard>
            </StatsWrapper>
        </SectionWrapper>
    )
}