import styled, { keyframes } from "styled-components";
import img from '../../assets/images/hero_bg.png';

const animationBG = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

export const HeroWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(
    -45deg, 
    rgba(6,10,61,1) 0%, 
    rgba(66,48,170,1) 25%, 
    rgba(6,10,61,1) 51%, 
    rgba(66,48,170,1) 100%
    );
    background-size: 300% 300%;
    height: 750px;
    position: relative;
    padding: 3rem 0 0;
    animation: ${animationBG} 15s ease infinite;
 
    &::before {
        content: "";
        background: url(${img});
        background-repeat: no-repeat;
        width: 100%;
        height: 1000px;
        background-size: cover;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    @media screen and (min-width: 1024px) {
        height: 1000px;
        padding: 6rem 0 0;
    }
`;

export const HeroHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    z-index: 999;
    text-align: center;
`;

export const HeroHeaderDetail = styled.div`
    & > h1 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 4px;
    }

    & > h2 {
        font-size: 42px;
        max-width: 650px;
        color: #fff;
        line-height: 1.1em;
    }

    & > p {
        color: #fff;
        margin: 10px 0 20px;
        font-size: 20px;
        line-height: 1.7;
    }
`;

export const ImageWrapper = styled.div`
    max-width: 920px;
    margin: 0 auto;
    position: relative;
    margin-top: 3rem;

    & > img {
        border: 2px solid #6a49f2;
        border-radius: 30px;
        width: 100%;
    }
`;
export const ButtonGroup = styled.div`

`;

export const HeaderButton = styled.a`
    text-decoration: none;
    background-image: linear-gradient(135deg, #ff7133, #ff8c5a);
    padding: 10px 30px;
    border-radius: 25px;
    position: relative;
    font-weight: 700;
    color: #fff;
    transition: .4s all;
    font-size: 15px;
    font-family: 'Red Hat Text', sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const MarqueeWrapper = styled.div`
    background-color: #C9F31D;
    padding: 1rem;
    font-size: 24px;
`;

export const MarqueeContent = styled.div`
    display: flex;
    gap: 3rem;
    & > img {
        width: 25px;
    }

    font-family: 'Red Hat Text', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-right: 3rem;
`;