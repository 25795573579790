import React from "react";
import { LuDot } from 'react-icons/lu';
import proj1 from '../../assets/images/projects/new/1.webp';
import proj7 from '../../assets/images/projects/new/10.webp';
import proj2 from '../../assets/images/projects/new/2.webp';
import proj3 from '../../assets/images/projects/new/3.webp';
import proj5 from '../../assets/images/projects/new/5.webp';
import proj4 from '../../assets/images/projects/new/6.webp';
import proj8 from '../../assets/images/projects/new/8.webp';
import proj9 from '../../assets/images/projects/new/9.webp';
import { Rain } from "../../component/rain";
import { SectionWrapper } from "../../styles";
import { ServiceContainer } from "../services/style";
import { ProjectCard, ProjectGrid, ProjectOtherFlex, ProjectOthers } from "./style";

const data = [
    {
        title: 'intrn.id',
        category: 'Education Media',
        image: proj1
    },
    {
        title: 'The Local Design Studio',
        category: 'Interior Studio',
        image: proj2
    },
    {
        title: 'TRUST',
        category: 'Education Platform',
        image: proj3
    },
    {
        title: 'KCP AI Cloud',
        category: 'Compute Engine Provider',
        image: proj4
    },
    {
        title: 'Talent Vest',
        category: 'Social Media',
        image: proj7
    },
    {
        title: 'Fitloop',
        category: 'Fitness Booking Apps',
        image: proj5
    },
    {
        title: 'Kelola Kurnia Alam',
        category: 'Company Profile',
        image: proj8
    },
    {
        title: 'Perdana Megajaya',
        category: 'Corrugated Box Company',
        image: proj9
    },
]

export const Project = () => {
    return (
        <SectionWrapper id="projects">
            <Rain />
            <ServiceContainer>
                <span data-aos="fade-right">Those Whom We Help Along The Way</span>
                <ProjectGrid data-aos="fade-up">
                    {data.map((item, index) => <ProjectCard key={index}>
                        <img src={item.image} alt={`Project ${item.title}`} />
                        <h1>{item.title}</h1>
                        <p>{item.category}</p>
                    </ProjectCard>)}
                    {/* <ProjectCard>
                        <img src={img1} alt="project" />
                        <h1>intrn.id</h1>
                        <p>Education Media</p>
                    </ProjectCard>
                    <ProjectCard>
                        <img src={img2} alt="project" />
                        <h1>The Local Design Studio</h1>
                        <p>Interior Studio</p>
                    </ProjectCard>
                    <ProjectCard>
                        <img src={img3} alt="project" />
                        <h1>KCP AI Cloud</h1>
                        <p>Compute Engine Provider</p>
                    </ProjectCard>
                    <ProjectCard>
                        <img src={img4} alt="project" />
                        <h1>Talent Vest</h1>
                        <p>CV Replacement</p>
                    </ProjectCard>
                    <ProjectCard>
                        <img src={img5} alt="project" />
                        <h1>Media Prima Jaringan</h1>
                        <p>Jasa Teknologi Informasi</p>
                    </ProjectCard>
                    <ProjectCard>
                        <img src={img6} alt="project" />
                        <h1>Fitloop</h1>
                        <p>Fitness Booking Apps</p>
                    </ProjectCard> */}
                </ProjectGrid>
                <ProjectOthers>
                    <span data-aos="fade-right">And some others ...</span>
                    <ProjectOtherFlex data-aos="fade-up">
                        <span>Kepoinn</span>
                        <span><LuDot /></span>
                        <span>Watchvul</span>
                        <span><LuDot /></span>
                        <span>WargaParkSerpong</span>
                    </ProjectOtherFlex>
                </ProjectOthers>
            </ServiceContainer>
        </SectionWrapper>
    )
}